
import { defineComponent } from "vue";
import "vue-loading-overlay/dist/vue-loading.css";
import { emitter } from "../../lib/EventBus";
import axios from "axios";
import { Tooltip } from "bootstrap";

interface Response {
  program: string;
}

export default defineComponent({
  name: "Amendments",
  data(): Record<string, Record<string, Record<string, string | string[]>>> {
    return {
      filters: {
        type: { value: "", keys: ["type"] },
      },
    };
  },
  mounted: function () {
    var exampleEl = document.getElementById("apply-help");
    new Tooltip(exampleEl, {});
  },
  props: {
    msg: String,
    amendments: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    applyAmendment(amendment_id: number) {
      axios
        .request<Response>({
          method: "post",
          url: "applyAmendment/",
          xsrfCookieName: "csrftoken",
          xsrfHeaderName: "X-CSRFToken",
          headers: {
            "X-CSRFToken": "csrftoken",
            "Content-Type": "application/json",
          },
          data: {
            amendment_id: amendment_id,
          },
        })
        .then((response) => {
          const new_program = response.data.program;
          void emitter.emit("updateInputProgram", new_program);
          void emitter.emit("closeSuggestionModal");
        });
    },
  },
});
