
import { defineComponent } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Tooltip } from "bootstrap";

export default defineComponent({
  mounted: function () {
    var prefHelp = document.getElementById("pref-help");
    new Tooltip(prefHelp, {});
  },
  components: {
    Loading,
  },
  name: "SolverModels",
  data(): Record<string, boolean> {
    return {
      isLoading: false,
    };
  },
  props: {
    msg: String,
    models: {
      type: Object,
      required: true,
    },
  },
});
