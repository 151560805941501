
import { defineComponent, ref } from "vue";
import Loading from "vue-loading-overlay";
import { Tooltip } from "bootstrap";
import "vue-loading-overlay/dist/vue-loading.css";
import { emitter } from "../../lib/EventBus";
import axios from "axios";
import ExplAttackers from "./ExplAttackers.vue";
import * as Util from "../../assets/js/util.js";

interface Response {
  atoms: Record<string, string>[];
  scup_id: string;
  atom_id: string;
  attackers: Record<string, string>;
  text: string;
}

interface ResponseScup {
  scup_id: string;
  atoms: Atom[];
}

interface Atom {
  id: string;
  name: string;
  label: string;
  is_scup: string;
}

interface Explanation {
  atoms: Atom[];
  scup_id: number;
  id: number;
}

interface ResultItem {
  id: string;
  name: string;
  label: string;
  is_scup: string;
}

export default defineComponent({
  name: "SolverExplanations",
  mounted: function () {
    emitter.on("scupChanged", (scup_id: number) => {
      this.onScupIdChanged(scup_id);
    });
    emitter.on("attacker_b_changed", (atom_id: string) => {
      this.onAttackerBChanged(atom_id);
    });
    this.explanations = {
      id: -1,
      scup_id: -1,
      atoms: [],
    };
  },
  setup() {
    var explanations = ref<Explanation>();
    var show_only_scup_atoms = ref<boolean>(true);
    var explanation_text = ref<string>("");
    const selected_atom_id = ref("-1");
    const selected_atom_id_b = ref("-1");
    const selected_scup_id = ref(-1);
    const attackers = ref();
    const attackers_b = ref();
    return {
      explanations,
      selected_atom_id,
      selected_atom_id_b,
      selected_scup_id,
      attackers,
      attackers_b,
      show_only_scup_atoms,
      explanation_text,
    };
  },
  components: {
    Loading,
    ExplAttackers,
  },
  watch: {
    show_only_scup_atoms: function () {
      this.resetAtoms();
      this.onAtomChanged();
    },
  },
  data(): Record<string, boolean> {
    return {
      isLoading: false,
      isLoadingAtt: false,
      isLoadingAtt_b: false,
    };
  },
  props: {
    msg: String,
  },
  methods: {
    onAtomChanged() {
      this.isLoadingAtt = true;
      this.selected_atom_id_b = "-1";
      this.updateAttackers(this.selected_atom_id, this.selected_scup_id);
      this.updateAttackers_b(this.selected_atom_id_b, this.selected_scup_id);
    },
    onAttackerBChanged(atom_id: string) {
      this.selected_atom_id_b = atom_id;
      this.updateAttackers_b(atom_id, this.selected_scup_id);
      this.isLoadingAtt_b = true;
    },
    onScupIdChanged(scup_id: number) {
      this.selected_atom_id = "-1";
      this.selected_atom_id_b = "-1";
      this.selected_scup_id = scup_id;
      this.isLoading = true;
      this.updateExplanationAtoms(scup_id);
    },
    updateExplanationAtoms(scup_id: number) {
      var filled = false;
      axios
        .request<ResponseScup>({
          method: "post",
          url: "getScupAtoms/",
          xsrfCookieName: "csrftoken",
          xsrfHeaderName: "X-CSRFToken",
          headers: {
            "X-CSRFToken": "csrftoken",
            "Content-Type": "application/json",
          },
          data: {
            scup_id: scup_id,
          },
        })
        .then((response) => {
          if (this.explanations && response.data.atoms) {
            this.explanations.atoms = response.data.atoms.map(
              (item: ResultItem): Atom => {
                return {
                  id: item.id,
                  name: item.name,
                  label: item.label,
                  is_scup: item.is_scup,
                };
              }
            );
            this.explanations.scup_id = response.data
              .scup_id as unknown as number;
            if (this.explanations.atoms.length > 0) {
              filled = true;
            }
          }
          this.updateAttackers(this.selected_atom_id, this.selected_scup_id);
          this.updateAttackers_b(
            this.selected_atom_id_b,
            this.selected_scup_id
          );
          this.isLoading = false;
        })
        .finally(() => {
          if (filled) {
            var atomHelp = document.getElementById("select-atom-help");
            new Tooltip(atomHelp, {});
          }
        });
    },
    getAtoms() {
      var all_atoms = [];
      let atoms: Array<Atom> = [];
      if (this.explanations) {
        all_atoms = this.explanations.atoms;
        if (this.show_only_scup_atoms) {
          all_atoms.forEach(function (atom) {
            if (atom.is_scup) {
              atoms.push(atom);
            }
          });
          return atoms;
        } else {
          return all_atoms;
        }
      }
      return [];
    },
    updateAttackers(atom_id: string, scup_id: number) {
      axios
        .request<Response>({
          method: "post",
          url: "getAttackers/",
          xsrfCookieName: "csrftoken",
          xsrfHeaderName: "X-CSRFToken",
          headers: {
            "X-CSRFToken": "csrftoken",
            "Content-Type": "application/json",
          },
          data: {
            scup_id: scup_id,
            atom_id: atom_id,
          },
        })
        .then((response) => {
          this.attackers = response.data.attackers;
          this.isLoadingAtt = false;
          this.explanation_text = response.data.text;
        })
        .finally(() => {
          emitter.emit("attacker_changed", this.selected_atom_id);
        });
    },
    updateAttackers_b(atom_id: string, scup_id: number) {
      axios
        .request<Response>({
          method: "post",
          url: "getAttackers/",
          xsrfCookieName: "csrftoken",
          xsrfHeaderName: "X-CSRFToken",
          headers: {
            "X-CSRFToken": "csrftoken",
            "Content-Type": "application/json",
          },
          data: {
            scup_id: scup_id,
            atom_id: atom_id,
          },
        })
        .then((response) => {
          this.attackers_b = response.data.attackers;
          this.isLoadingAtt_b = false;
        })
        .catch((error) => {
          this.log = error.response.data;
          this.$notify(Util.notifyException(error));
        });
    },
    reset() {
      this.resetAtoms();
      this.selected_scup_id = -1;
    },
    resetAtoms() {
      this.selected_atom_id = "-1";
      this.selected_atom_id_b = "-1";
    },
  },
});
