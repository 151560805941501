<template>
  <div class="content">
    <div class="inner-content">
      <notifications position="top center">
        <button class="close" @click="close">
          <i class="fa fa-fw fa-close"></i>
        </button>
      </notifications>
      <div class="container">
        <div class="row p-1 m-1">
          <div class="col-lg-12 col-md-12">
            <div class="row shadow-sm p-1 m-1 rounded bg-light">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <form>
                  <div class="field is-grouped">
                    <div class="mb-2">
                      contact info
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
