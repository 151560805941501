
import { defineComponent, ref } from "vue";
import axios from "axios";

export default defineComponent({
  methods: {
    start(event: Event): void {
      event.preventDefault();
      console.log("hello world")
      axios
        .request<Response>({
          method: "post",
          url: "generate/",
          xsrfCookieName: "csrftoken",
          xsrfHeaderName: "X-CSRFToken",
          headers: {
            "X-CSRFToken": "csrftoken",
            "Content-Type": "application/json",
          },
          data: {
          },
        })
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        });
    },
  }
});
