
import { defineComponent, reactive, ref, PropType } from "vue";
import { GridLayout } from "v-network-graph";
import * as vNG from "v-network-graph";
import axios from "axios";
import { emitter } from "../../lib/EventBus";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Tooltip } from "bootstrap";

interface Node extends vNG.Node {
  size: number;
  color: string;
  label?: boolean;
  strokeWidth: number;
  strokeColor?: string;
  strokeDasharray?: string;
}

interface Edge extends vNG.Edge {
  width: number;
  color: string;
  dashed?: boolean;
}

interface Response {
  nodes_edges: {
    nodes: [];
    edges: [];
  };
  scups: [];
  message: string;
  type: string;
}

interface Model {
  id: number;
  true: string;
  false: string;
}

export default defineComponent({
  components: {
    Loading,
  },
  props: {
    models: {
      type: Array as PropType<Model[]>,
      default: () => [],
    },
    edge: {
      label: String,
      default: () => Object(),
    },
    slotProps: {
      type: String,
      default: () => "",
    },
    input_program: {
      type: String,
      default: () => "",
    },
    literals: {
      type: Array,
      default: () => [],
    },
  },
  mounted: function () {
    this.graph.zoomOut();

    var fitHelp = document.getElementById("fit-to-content-help");
    new Tooltip(fitHelp, {});
    var modelHelp = document.getElementById("select-model-help");
    new Tooltip(modelHelp, {});
  },
  setup() {
    const isLoading = ref();
    const selected_model = -1;
    const sel_models = reactive({});
    const nodes = reactive<Record<string, Node>>({});
    const edges = reactive<Record<string, Edge>>({});
    const layouts = reactive<
      Record<string, Record<string, Record<string, number>>>
    >({
      nodes: {
        node1: {
          x: 0,
          y: 0,
        },
      },
    });
    const configs = reactive(
      vNG.configsWithType<Node, Edge>({
        view: {
          layoutHandler: new GridLayout({ grid: 20 }),
          grid: {
            visible: true,
          },
        },
        node: {
          normal: {
            type: "circle",
            radius: (node) => node?.size,
            color: (node) => node?.color,
            strokeWidth: (node) => node?.strokeWidth,
            strokeColor: (node) => node?.strokeColor,
            strokeDasharray: (node) => node?.strokeDasharray,
          },
          hover: {
            radius: (node) => node?.size + 2,
            color: (node) => node.color,
          },
          selectable: false,
          label: {
            visible: (node) => !!node?.label,
          },
        },
        edge: {
          type: "curve",
          normal: {
            width: (edge) => edge.width, // Use the value of each edge object
            color: (edge) => edge.color,
            dasharray: (edge) => (edge.dashed ? "4" : "0"),
          },
          marker: {
            target: { type: "arrow" },
          },
        },
      })
    );

    const nextEdgeIndex = ref(Object.keys(edges).length + 1);
    const graph = ref();

    return {
      nodes,
      edges,
      layouts,
      configs,
      nextEdgeIndex,
      graph,
      selected_model,
      sel_models,
      isLoading,
    };
  },
  methods: {
    updateSelectedModel() {
      this.loadNodes();
      this.graph.panToCenter();
    },
    reset() {
      this.selected_model = -1;
      this.resetGraph();
    },
    getColorByLabelId(id: number) {
      if (id == 0) return "red";
      if (id == 1) return "gray";
      return "green";
    },
    resetGraph() {
      this.removeAllNodes();
      this.removeAllEdges();
    },
    loadNodes() {
      this.resetGraph();
      this.isLoading = true;

      axios
        .request<Response>({
          method: "post",
          url: "../getArgGraphData/",
          xsrfCookieName: "csrftoken",
          xsrfHeaderName: "X-CSRFToken",
          headers: {
            "X-CSRFToken": "csrftoken",
            "Content-Type": "application/json",
          },
          data: {
            input_program: this.input_program,
            selected_model: this.selected_model,
          },
        })
        .then((response) => {
          var key = "";
          var edgeSource = "";
          var edgeTarget = "";
          var edgeWeight = "";
          var nodes = Object.values(response.data.nodes_edges.nodes);
          var edges = Object.values(response.data.nodes_edges.edges);
          var scups = Object.values(response.data.scups);
          void emitter.emit("updateScups", scups);

          for (const node of Object.values(nodes)) {
            const newNode: Node = {
              name: `${node["name"]}`,
              size: 16,
              label: true,
              color: this.getColorByLabelId(node["label"]),
              strokeWidth: node["flagged"] == true ? 2 : 0,
              strokeColor: "#f44336",
              strokeDasharray: "3",
            };
            this.addNode(node["uid"], newNode);
            this.addPosition(node["uid"], node["pos_x"], node["pos_y"]);
          }

          for (const edge of edges) {
            key = `edge${Object.keys(this.edges).length}`;
            edgeSource = `${edge["source"]}`;
            edgeTarget = `${edge["target"]}`;
            edgeWeight = `${edge["weight"]}`;

            this.edges[key] = {
              source: edgeSource,
              target: edgeTarget,
              width: 2,
              dashed: false,
              color: "skyblue",
              label: edgeWeight == "1" ? "" : edgeWeight,
            };
          }
        })
        .catch((error) => {
          const errorText = error.response.data.message || error.message;
          const errorType = error.response.data.type || error.type;
          this.isLoading = false;
          this.$notify({
            title: errorType,
            text: errorText,
          });
        })
        .finally(() => {
          this.isLoading = false;
          if (Object.keys(this.nodes).length > 0) this.graph?.fitToContents();
        });
    },
    removeAllNodes() {
      for (const [key] of Object.entries(this.nodes)) {
        delete this.nodes[key];
      }
    },
    removeAllEdges() {
      for (const [key] of Object.entries(this.edges)) {
        delete this.edges[key];
      }
    },
    addNode(id: string, node: Node) {
      this.nodes[id] = node;
    },
    addPosition(id: string, pos_x: number, pos_y: number) {
      this.layouts.nodes[id] = {
        x: pos_x,
        y: pos_y,
      };
    },
  },
});
