
import { defineComponent, PropType } from "vue";

interface iProgram {
  id: number;
  rules: string;
}

export default defineComponent({
  name: "SolverHistory",
  props: {
    msg: String,
    solver_history: {
      type: Array as PropType<iProgram[]>,
      default: () => [],
    },
  },
  methods: {
    getPanelId(atom: string) {
      return "panel-id-" + atom;
    },
    getRefPanelId(atom: string) {
      return "#" + this.getPanelId(atom);
    },
  },
});
