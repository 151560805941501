
import { defineComponent, PropType } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

interface iAnswerset {
  //  [index: number]: string;
  id: number;
  answerset: string;
}

export default defineComponent({
  components: {
    Loading,
  },
  mounted: function () {
    // console.log("Webhook should work now!");
    // console.log("Webhook should work now, really!");
  },
  name: "SolverAnswersets",
  data(): Record<string, boolean> {
    return {
      isLoading: false,
    };
  },
  props: {
    msg: String,
    answersets: {
      type: Array as PropType<iAnswerset[]>,
      required: true,
      default: () => [],
    },
  },
});
