
import { defineComponent, ref } from "vue";
import SolverAnswersets from "../components/solver/SolverAnswersets.vue";
import SolverDefaultRules from "../components/solver/SolverDefaultRules.vue";
import SolverHistory from "../components/solver/SolverHistory.vue";
import SolverArgGraph from "../components/solver/SolverArgGraph.vue";
import SolverModels from "../components/solver/SolverModels.vue";
import SolverScups from "../components/solver/SolverScups.vue";
import SolverExplanations from "@/components/solver/SolverExplanations.vue";
import { emitter } from "../lib/EventBus";
import axios from "axios";
import * as Util from "../assets/js/util.js";
import { Tooltip } from "bootstrap";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

type Entry = [string | number, string | number | iModel];

interface iModel {
  id: number;
  true: string;
  false: string;
}

interface iProgram {
  id: number;
  rules: string;
}

interface Response {
  input_program: string;
  trees: string;
  solver_program: string;
  solver_history: string;
  answersets: string;
  literals: string;
  solver_default_rules: string;
  models: string;
  scups: string;
  working_program: string;
}

export default defineComponent({
  name: "App",
  components: {
    SolverAnswersets,
    SolverHistory,
    SolverDefaultRules,
    SolverArgGraph,
    SolverModels,
    SolverScups,
    SolverExplanations,
  },
  setup() {
    let preset_programs = new Map([
      ["pre-0", ""],
      [
        "pre-1",
        "a :- not b.\nb :- not c.\nc :- not a, not r. \nr :- not s. \ns :- not r. \n\nm :- not o. \no :- not p. \no :- not q. \np :- not m, not z. \nq :- not m, not z. \n\ng :- not k. \nk :- not o. \no :- not g. \n\nx :- not y. \ny :- not z. \nz :- not x. \n\nd :- not e. \ne :- not d. \n\na :- d.",
      ],
      [
        "pre-2",
        "d :- not e. \ne :- not d. \n\na :- d. \n\nx :- not y. \ny :- not z. \n\no :- not g. \ng :- not k. \nk :- not o. \no :- not p. \np :- not m, not z. \nm :- not o. \no :- not q. \nq :- not m, not z. \n\ns :- not r. \nr :- not s. \n\nc :- not a, not r. \na :- not b. \nb :- not c. \n\nz.",
      ],
    ]);
    let preset_id = ref<string>("pre-0");
    return {
      preset_id,
      preset_programs,
    };
  },
  mounted() {
    new Tooltip(document.getElementById("input-program-help"), {});
    new Tooltip(document.getElementById("solve-help"), {});
    new Tooltip(document.getElementById("preset-program-help"), {});
    emitter.on("updateInputProgram", (data) => {
      this.input_program = data;
      this.getSolve(new Event("click"));
    });
  },
  data(): Record<
    string,
    string[] | number | string | boolean | iModel[] | iProgram
  > {
    return {
      input_program: "",
      answersets: [],
      literals: [],
      attacktrees: [],
      solver_history: [],
      solver_default_rules: [],
      selected_answerset: -1,
      selected_model: -1,
      selected_literal: "",
      models: [],
      log: [],
      debug_log: [],
      scups: [],
      working_program: "",
    };
  },
  methods: {
    // https://stackoverflow.com/a/45390578/7684461
    hasKey<K extends string>(
      k: K,
      // eslint-disable-next-line
      x: any
    ): x is Record<K, Record<string, never>> {
      return k in x;
    },
    // eslint-disable-next-line
    isIModel(x: any): x is iModel {
      return (
        this.hasKey("id", x) &&
        this.hasKey("true", x) &&
        this.hasKey("false", x)
      );
    },
    getModelFromJson(json: Entry) {
      let model_array: iModel[] = new Array(0);
      Object.entries(json).forEach(([key, value]: Entry) => {
        if (this.isIModel(value)) {
          var m: iModel = {
            id: value.id,
            true: value.true,
            false: value.false,
          };
          model_array[Number(key)] = m;
        }
      });
      return model_array;
    },
    getSolve(event: Event): void {
      event.preventDefault();
      this.reset();
      (this.$refs.solverArgGraph as typeof SolverArgGraph).reset();
      (this.$refs.solverArgGraph as typeof SolverArgGraph).isLoading = false;
      (this.$refs.solverAnswersets as typeof SolverAnswersets).isLoading = true;
      (this.$refs.solverDefaultRules as typeof SolverAnswersets).isLoading =
        true;
      (this.$refs.solverModels as typeof SolverModels).isLoading = true;
      (this.$refs.solverScups as typeof SolverScups).isLoading = true;
      (this.$refs.solverExplanations as typeof SolverExplanations).isLoading =
        true;

      axios
        .request<Response>({
          method: "post",
          url: "solve/",
          xsrfCookieName: "csrftoken",
          xsrfHeaderName: "X-CSRFToken",
          headers: {
            "X-CSRFToken": "csrftoken",
            "Content-Type": "application/json",
          },
          data: {
            input_program: this.input_program,
          },
        })
        .then((response) => {
          (this.models = this.getModelFromJson(
            JSON.parse(response.data.models)
          )),
            (this.trees = response.data.trees),
            (this.answersets = JSON.parse(response.data.answersets)),
            (this.solver_history = JSON.parse(response.data.solver_history)),
            (this.literals = JSON.parse(response.data.literals)),
            (this.solver_default_rules = JSON.parse(
              response.data.solver_default_rules
            ));
          (this.$refs.solverAnswersets as typeof SolverAnswersets).isLoading =
            false;
          (this.$refs.solverDefaultRules as typeof SolverAnswersets).isLoading =
            false;
          (this.$refs.solverModels as typeof SolverModels).isLoading = false;
          (this.$refs.solverScups as typeof SolverScups).isLoading = false;
          (
            this.$refs.solverExplanations as typeof SolverExplanations
          ).isLoading = false;
          (this.$refs.solverArgGraph as typeof SolverArgGraph).isLoading =
            false;
        })
        .catch((error) => {
          this.log = error.response.data;
          this.$notify(Util.notifyException(error));
          (this.$refs.solverAnswersets as typeof SolverAnswersets).isLoading =
            false;
          (this.$refs.solverDefaultRules as typeof SolverAnswersets).isLoading =
            false;
          (this.$refs.solverModels as typeof SolverModels).isLoading = false;
          (this.$refs.solverScups as typeof SolverScups).isLoading = false;
          (
            this.$refs.solverExplanations as typeof SolverExplanations
          ).isLoading = false;
          (this.$refs.solverArgGraph as typeof SolverArgGraph).isLoading =
            false;
        });
    },
    updateSelectedModel(event: Event): void {
      (this.$refs.solverArgGraph as typeof SolverArgGraph).loadNodes(
        (event.target as HTMLInputElement).value,
        this.selected_model
      );
    },
    reset(): void {
      this.trees = [];
      this.solver_history = [];
      this.answersets = [];
      this.attacktrees = [];
      this.solver_default_rules = [];
      this.models = [];
      this.scups = [];
      this.working_program = "";
      void emitter.emit("updateScups", this.scups);
    },
    onPresetProgramChanged(): void {
      this.reset();
      if (this.preset_programs.has(this.preset_id)) {
        const preset_program = this.preset_programs.get(this.preset_id);
        if (preset_program != undefined) {
          this.input_program = preset_program;
        }
      } else {
        this.input_program = "";
      }
    },
  },
});
