import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row shadow-sm p-3 bg-body rounded" }
const _hoisted_2 = { class: "col-lg-12 col-md-12 col-xs-12" }
const _hoisted_3 = { class: "col-lg-12 col-md-12 col-xs-12" }
const _hoisted_4 = {
  key: 0,
  class: "h-100",
  style: {"position":"relative"}
}
const _hoisted_5 = { class: "invisible" }
const _hoisted_6 = {
  key: 1,
  class: "list-group"
}
const _hoisted_7 = {
  key: 2,
  class: "text-center fst-italic text-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", null, _toDisplayString(_ctx.msg), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.msg), 1),
            _createVNode(_component_loading, {
              active: true,
              height: 20,
              "is-full-page": false,
              "can-cancel": true
            })
          ]))
        : (_ctx.answersets && _ctx.answersets.length > 0)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answersets, (answerset) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "list-group-item",
                  key: answerset['id']
                }, _toDisplayString(answerset["answerset"]), 1))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, "Empty"))
    ])
  ]))
}