
import { defineComponent, PropType } from "vue";
import "vue-loading-overlay/dist/vue-loading.css";
import { emitter } from "../../lib/EventBus";
import { Tooltip } from "bootstrap";

interface Attacker {
  atoms: string;
  label: string;
  id: string;
  rules: string;
  text: string;
}

export default defineComponent({
  name: "ExplAttackers",
  data(): Record<string, Record<string, Record<string, string | string[]>>> {
    return {
      filters: {
        type: { value: "", keys: ["type"] },
      },
    };
  },
  mounted: function () {
    emitter.on("attacker_changed", (atom_id: string) => {
      if (atom_id != "-1") {
        this.enableAttackerTooltip();
      }
    });
  },
  methods: {
    enableAttackerTooltip() {
      var attackerHelp = document.getElementById("attacker-help");
      if (attackerHelp) {
        new Tooltip(attackerHelp, {});
      }
    },
    getPanelId(atom: string) {
      return "panel-id-" + atom.replace(/.\| /g, "-");
    },
    getPanelName(atom: string) {
      return "panel-" + atom.replace(/.\| /g, "-");
    },
    getRefPanelName(atom: string) {
      return "#" + this.getPanelName(atom);
    },
    onAttackerClick(atom_id: string) {
      emitter.emit("attacker_b_changed", atom_id);
    },
    getPillClassByLabelName(label_name: string) {
      if (label_name == "UNDECIDED") {
        return "badge rounded-pill text-bg-secondary";
      }
      if (label_name == "FALSE") {
        return "badge rounded-pill text-bg-danger";
      }
      if (label_name == "TRUE") {
        return "badge rounded-pill text-bg-success";
      }
    },
    getAttackerName(atoms: string) {
      if (atoms != "") {
        return atoms;
      }
      return "[empty node]";
    },
  },
  props: {
    msg: String,
    attackers: {
      type: Array as PropType<Attacker[]>,
      default: () => [],
    },
    clickable: Boolean,
    scup_id: Number,
    atom_id: String,
    text: String,
  },
});
