import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row shadow-sm p-3 bg-body rounded" }
const _hoisted_2 = { class: "col-lg-12 col-md-12 col-xs-12" }
const _hoisted_3 = {
  class: "ms-1",
  id: "pref-help",
  "data-bs-toggle": "tooltip",
  "data-bs-placement": "right",
  title: "<X,Y> intuitively means that all atoms in X are true, and all atoms in Y are false"
}
const _hoisted_4 = { class: "col-lg-12 col-md-12 col-xs-12" }
const _hoisted_5 = {
  key: 0,
  class: "h-100",
  style: {"position":"relative"}
}
const _hoisted_6 = { class: "invisible" }
const _hoisted_7 = {
  key: 1,
  class: "text-center fst-italic text-secondary"
}
const _hoisted_8 = {
  key: 2,
  class: "list-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_icon_question_circle = _resolveComponent("b-icon-question-circle")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", null, [
        _createTextVNode(_toDisplayString(_ctx.msg) + " ", 1),
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_b_icon_question_circle)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.msg), 1),
            _createVNode(_component_loading, {
              active: true,
              height: 20,
              "is-full-page": false,
              "can-cancel": true
            })
          ]))
        : (_ctx.models && _ctx.models.length == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Empty "))
          : (_openBlock(), _createElementBlock("ul", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.models, (model) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "list-group-item",
                  key: model['id']
                }, " < { " + _toDisplayString(model["true"]) + " }, { " + _toDisplayString(model["false"]) + " } > ", 1))
              }), 128))
            ]))
    ])
  ]))
}