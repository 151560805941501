import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "inner-content" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row p-1 m-1" }
const _hoisted_5 = { class: "col-lg-12 col-md-12" }
const _hoisted_6 = { class: "row shadow-sm p-1 m-1 rounded bg-light" }
const _hoisted_7 = { class: "col-lg-12 col-md-12 col-sm-12" }
const _hoisted_8 = { class: "field is-grouped" }
const _hoisted_9 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("form", null, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("button", {
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.start && _ctx.start(...args)))
                      }, " Start ")
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}