<template>
  <!--<ul class="nav nav-tabs">-->
  <!--  <li class="nav-item">-->
  <!--    <router-link class="nav-link" to="/">Solver</router-link>-->
  <!--  </li>-->
  <!--  <li class="nav-item">-->
  <!--    <router-link class="nav-link" to="/contact/">Contact</router-link>-->
  <!--  </li>-->
  <!--</ul>-->
  <router-view />
</template>
