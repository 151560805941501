import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-12 col-md-12 col-sm-12" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "list-group-item w-75" }
const _hoisted_6 = { class: "list-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.scup && _ctx.scup.entries && _ctx.scup.entries.rules)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scup.entries.rules, (rule, rule_index) => {
              return (_openBlock(), _createElementBlock("ul", {
                class: "list-group list-group-horizontal mb-1",
                key: rule_index
              }, [
                _createElementVNode("li", {
                  class: "list-group-item fw-bold w-25",
                  innerHTML: 'Node ' + rule_index
                }, null, 8, _hoisted_4),
                _createElementVNode("li", _hoisted_5, [
                  _createElementVNode("ul", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rule, (item, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        style: {"list-style-type":"none"},
                        key: index
                      }, _toDisplayString(item.string), 1))
                    }), 128))
                  ])
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}