import VNetworkGraph from "v-network-graph";
import Notifications from "@kyvg/vue3-notification";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
// https://icons.getbootstrap.com/#icons
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import SmartTable from "vuejs-smart-table";

const app = createApp(App, {});
app.use(VNetworkGraph);
app.use(Notifications);
app.use(router).mount("#app");
app.use(VueLoading);
app.use(BootstrapIconsPlugin);
app.use(SmartTable);
