import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row shadow-sm p-3 bg-body rounded" }
const _hoisted_2 = { class: "col-lg-12 col-md-12 col-xs-12" }
const _hoisted_3 = {
  class: "accordion",
  id: "accordionExample"
}
const _hoisted_4 = { class: "accordion-header" }
const _hoisted_5 = ["data-bs-target", "aria-controls"]
const _hoisted_6 = ["id"]
const _hoisted_7 = { class: "accordion-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", null, _toDisplayString(_ctx.msg), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.solver_history, (program) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "accordion-item",
          key: program.id
        }, [
          _createElementVNode("h2", _hoisted_4, [
            _createElementVNode("button", {
              class: "accordion-button collapsed",
              type: "button",
              "data-bs-toggle": "collapse",
              "data-bs-target": _ctx.getRefPanelId(program.id),
              "aria-expanded": "false",
              "aria-controls": _ctx.getRefPanelId(program.id)
            }, " Program " + _toDisplayString(program.id), 9, _hoisted_5)
          ]),
          _createElementVNode("div", {
            id: _ctx.getPanelId(program.id),
            class: "accordion-collapse collapse",
            "data-bs-parent": "#accordionExample"
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("pre", null, _toDisplayString(program.rules), 1)
            ])
          ], 8, _hoisted_6)
        ]))
      }), 128))
    ])
  ]))
}