import { createWebHistory, createRouter } from "vue-router";
import App from "@/views/Solver.vue";
import Contact from "@/views/Contact.vue";
import Experiement from "@/views/Experiment.vue";
import NotFound from "@/views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "App",
    component: App,
  },
  {
    path: "/Experiment/",
    name: "Experiment",
    component: Experiement,
  },
  {
    path: "/Contact/",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
