
import { defineComponent, PropType } from "vue";
import Loading from "vue-loading-overlay";

interface iRule {
  id: number;
}

export default defineComponent({
  name: "SolverDefaultRules",
  components: {
    Loading,
  },
  props: {
    msg: String,
    solver_default_rules: {
      type: Array as PropType<iRule[]>,
      default: () => [],
    },
  },
  data(): Record<string, boolean> {
    return {
      isLoading: false,
    };
  },
});
