
import { defineComponent, PropType } from "vue";
import "vue-loading-overlay/dist/vue-loading.css";

interface iScup {
  id: number;
  entries: iEntries;
}

interface iEntry {
  id: number;
  string: string;
}

interface iEntries {
  id: number;
  rules: iEntry[];
}
export default defineComponent({
  name: "ScupDetails",
  props: {
    scup: {
      type: Object as PropType<iScup>,
    },
  },
});
