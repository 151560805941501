
import { defineComponent, ref, reactive } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { emitter } from "../../lib/EventBus";
import Amendments from "./Amendments.vue";
import ScupDetails from "./ScupDetails.vue";
import axios from "axios";
import { Modal } from "bootstrap";
import { Tooltip } from "bootstrap";

interface Response {
  res: string;
  amendments: Record<string, string>;
  program: string;
}

interface iEntry {
  id: number;
  string: string;
}

interface iEntries {
  id: number;
  rules: iEntry[];
}

interface iScup {
  id: number;
  entries: iEntries;
}

export default defineComponent({
  name: "SolverScups",
  setup() {
    const scups = ref();
    const amendments = ref();
    const selected_scup_id = ref(-1);
    const selected_scup = reactive<iScup>(Object());
    return {
      scups,
      amendments,
      selected_scup,
      selected_scup_id,
    };
  },
  mounted() {
    var causeHelp = document.getElementById("cause-help");
    new Tooltip(causeHelp, {});

    emitter.on("updateScups", (data) => {
      let s = new Map();
      data.forEach(function (
        value: Record<
          string,
          | number
          | Map<string, Array<Map<string, number | string>>>
          | Array<string>
        >
      ) {
        s.set(value.id, value);
      });
      this.scups = s;
      this.selected_scup_id = -1;
      this.scupIdChanged();
    });
    emitter.on("closeSuggestionModal", () => {
      if (this.suggestionModal != null) {
        this.suggestionModal.hide();
      }
      window.scrollTo(0, 0);
    });
    this.suggestionModal = new Modal(
      document.getElementById("suggestionModal")
    );
  },
  components: {
    Loading,
    Amendments,
    ScupDetails,
  },
  watch: {
    selected_scup_id() {
      var e = document.getElementById("modify-help")
      if(e) {
        new Tooltip(e, {});
      }
    },
  },
  data(): Record<string, boolean | null | iScup | typeof Modal> {
    return {
      isLoading: false,
      suggestionModal: null,
    };
  },
  props: {
    msg: String,
  },
  methods: {
    scupIdChanged() {
      this.selected_scup_id = Number(this.selected_scup_id);
      this.selected_scup.entries = this.scups.get(this.selected_scup_id);
      emitter.emit("scupChanged", this.selected_scup_id);
    },
    reset() {
      this.selected_scup = {
        id: -1,
        entries: {
          id: -1,
          rules: [],
        },
      };
    },
    loadAmendments(scup_id: number) {
      axios
        .request<Response>({
          method: "post",
          url: "getAmendments/",
          xsrfCookieName: "csrftoken",
          xsrfHeaderName: "X-CSRFToken",
          headers: {
            "X-CSRFToken": "csrftoken",
            "Content-Type": "application/json",
          },
          data: {
            scup_id: scup_id,
          },
        })
        .then((response) => {
          this.amendments = response.data.amendments;
        });
    },
  },
});
